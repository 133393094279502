import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, CircularProgress, Grid, IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { ICompany } from "../../interfaces/ICompany";


interface IDateResult {
    Date: Date;
    Amount: number;
    Convergens: number;
    Text: string;
}

const monthNames = ["Januar", "Februar", "März", "April", "Mail", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

const LeadsStatistic: React.FC = () => {
    //const dispatch = useDispatch();
    //const rentArray = useSelector((state: State) => state.rentArray.storeRent);

    //const [personArray, setPersonArray, wasSuccessfullyLoad] = useFetch<IPerson[]>("person");
    const [personArray,setPersonArray,wasSuccessfullyLoad] = useFetch<ICompany[]>("/company/")
    //
    const [nowDate, setNowDate] = useState<Date>(new Date());
    const [nowDateMonth, setNowDateMonth] = useState<Date>(new Date());
    const [nowDateYear, setNowDateYear] = useState<Date>(new Date("2020-01-01"));

    const tsxDateIteration = () => {
        let returnValue: IDateResult[] = [];
        if (personArray != undefined) {
            let now = new Date(nowDate);
            let currentDate = new Date(now.getFullYear(), now.getMonth(), 1);
            let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            personArray.map(x => { console.log(x.created_at) });

            while (currentDate <= lastDay) {
                let newDate = currentDate.setDate(currentDate.getDate() + 1);
                currentDate = new Date(newDate);

                let engString = currentDate.toISOString().split("T")[0];
                let splittetDateString = engString.split("-");

                let amount = 0;
                let amountConversion = 0;
                personArray.filter(x => x.created_at != undefined).filter(x => x.created_at!.split("T")[0] === engString).map(x => {
                    amount += 1;
                    if (x.CompanyLeadStage === 7) {
                        amountConversion += 1;
                    }
                })

                returnValue.push({
                    Date: currentDate,
                    Amount: amount, //personArray.filter(x => x.Lead_Created_at != undefined).filter(x => x.Lead_Created_at!.split("T")[0] === engString).length,
                    //amount,
                    Convergens: amountConversion,
                    Text: `${splittetDateString[2]}.${splittetDateString[1]}.${splittetDateString[0]}`
                } as IDateResult)
            }
        }
        return returnValue;
    }


    const tsxLeadPerMonth = () => {
        let returnValue: IDateResult[] = [];

        let now = new Date(nowDateMonth);
        let currentDate = new Date(now.getFullYear(), 0, 2);

        if (personArray != undefined) {
            while (currentDate.getMonth() < 11) {
                let amount = 0;
                let amountConversion = 0;
                personArray.filter(x => x.created_at != undefined)
                    .filter(x =>
                        new Date(x.created_at!).getMonth() === currentDate.getMonth()
                        && new Date(x.created_at!).getFullYear() === currentDate.getFullYear()
                    ).map(x => {
                        amount += 1;
                        if (x.CompanyLeadStage === 7) {
                            amountConversion += 1;
                        }
                    })
                returnValue.push({
                    Date: currentDate,
                    Amount: amount,
                    /*
                    personArray!
                        .filter(x => x.Lead_Created_at != undefined)
                        .filter(x =>
                            new Date(x.Lead_Created_at!).getMonth() === currentDate.getMonth()
                            && new Date(x.Lead_Created_at!).getFullYear() === currentDate.getFullYear()
                        )
                        .length,
                        */
                    Convergens: amountConversion,
                    Text: monthNames[currentDate.getMonth()],

                } as IDateResult);

                let newDate = currentDate.setMonth(currentDate.getMonth() + 1);
                currentDate = new Date(newDate);
            }

            let amount = 0;
            let amountConversion = 0;
            personArray.filter(x => x.created_at != undefined)
                .filter(x =>
                    new Date(x.created_at!).getMonth() === currentDate.getMonth()
                    && new Date(x.created_at!).getFullYear() === currentDate.getFullYear()
                ).map(x => {
                    amount += 1;
                    if (x.CompanyLeadStage === 7) {
                        amountConversion += 1;
                    }
                })

            returnValue.push({
                Date: currentDate,
                Amount: amount,
                /*personArray!
                    .filter(x => x.Lead_Created_at != undefined)
                    .filter(x =>
                        new Date(x.Lead_Created_at!).getMonth() === currentDate.getMonth()
                        && new Date(x.Lead_Created_at!).getFullYear() === currentDate.getFullYear()
                    )
                    .length,
                    */
                Convergens: amountConversion,
                Text: monthNames[currentDate.getMonth()]
            } as IDateResult);
        }
        return returnValue;
    }

    const tsxLeadPerYear = () => {
        let returnValue: IDateResult[] = [];

        let now = new Date(nowDateYear);
        let currentDate = new Date(now.getFullYear(), 0, 2);
        let lastYear = currentDate.getFullYear() + 10;

        if (personArray != undefined) {
            while (currentDate.getFullYear() < lastYear) {
                let amount = 0;
                let amountConversion = 0;
                personArray
                    .filter(x => x.created_at != undefined)
                    .filter(x =>
                        new Date(x.created_at!).getFullYear() === currentDate.getFullYear()
                    ).map(x => {
                        amount += 1;
                        if (x.CompanyLeadStage === 7) {
                            amountConversion += 1;
                        }
                    })
                returnValue.push({
                    Date: currentDate,
                    Amount: amount,
                    /*personArray!
                        .filter(x => x.Lead_Created_at != undefined)
                        .filter(x =>
                            new Date(x.Lead_Created_at!).getFullYear() === currentDate.getFullYear()
                        )
                        .length,
                        */
                    Convergens: amountConversion,
                    Text: String(currentDate.getFullYear())
                } as IDateResult);

                let newDate = currentDate.setFullYear(currentDate.getFullYear() + 1);
                currentDate = new Date(newDate);
            }
        }
        return returnValue;
    }


    if ((wasSuccessfullyLoad !== null && wasSuccessfullyLoad !== 200)) { return <>Fehler!</> }
    else if (personArray == undefined) { return <LinearProgress /> }
    else {
        return (
            <>
                <Typography variant='h4' gutterBottom>
                    Leadstatistik
                </Typography>

                <Grid container spacing={2} >
                    <Grid item sm={4}>
                        <Typography variant="h6" align="center">Tagesansicht</Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant="h6" align="center">Monatsansicht</Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant="h6" align="center">Jahresansicht</Typography>
                    </Grid>

                    <Grid item sm={1} >
                        <IconButton onClick={() => setNowDate(new Date(nowDate.setMonth(nowDate.getMonth() - 1)))}><ArrowLeft /></IconButton>
                    </Grid>
                    <Grid item sm={2} >
                        <Typography align="center">{monthNames[nowDate.getMonth()]}</Typography>
                    </Grid>
                    <Grid item sm={1} >
                        <IconButton sx={{ float: "right" }} onClick={() => setNowDate(new Date(nowDate.setMonth(nowDate.getMonth() + 1)))}><ArrowRight /></IconButton>
                    </Grid>

                    <Grid item sm={1} >
                        <IconButton onClick={() => setNowDateMonth(new Date(nowDateMonth.setFullYear(nowDateMonth.getFullYear() - 1)))}><ArrowLeft /></IconButton>
                    </Grid>
                    <Grid item sm={2} >
                        <Typography align="center">{nowDateMonth.getFullYear()}</Typography>
                    </Grid>
                    <Grid item sm={1} >
                        <IconButton sx={{ float: "right" }} onClick={() => setNowDateMonth(new Date(nowDateMonth.setFullYear(nowDateMonth.getFullYear() + 1)))}><ArrowRight /></IconButton>
                    </Grid>


                    <Grid item sm={1} >
                        <IconButton onClick={() => setNowDateYear(new Date(nowDateYear.setFullYear(nowDateYear.getFullYear() - 10)))}><ArrowLeft /></IconButton>
                    </Grid>
                    <Grid item sm={2} >
                        <Typography align="center">{nowDateYear.getFullYear()} - {nowDateYear.getFullYear() + 9}</Typography>
                    </Grid>
                    <Grid item sm={1} >
                        <IconButton sx={{ float: "right" }} onClick={() => setNowDateYear(new Date(nowDateYear.setFullYear(nowDateYear.getFullYear() + 10)))}><ArrowRight /></IconButton>
                    </Grid>

                    <Grid item sm={4}>
                        <Box sx={{ fontSize: 3 }}>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Datum</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Leads</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Kunden</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>CVR</Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {tsxDateIteration().map(x =>
                                        <TableRow>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Text}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Amount}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Convergens}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{(x.Convergens != 0 && x.Amount != 0) ? ((100 * x.Convergens / x.Amount).toFixed(2).toString() + " %").replace(".", ",") : "-"}</Typography></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>

                    <Grid item sm={4}>
                        <Box>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Datum</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Leads</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Kunden</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>CVR</Typography></TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {tsxLeadPerMonth().map(x =>
                                        <TableRow>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Text}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Amount}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Convergens}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{(x.Convergens != 0 && x.Amount != 0) ? ((100 * x.Convergens / x.Amount).toFixed(2).toString() + " %").replace(".", ",") : "-"}</Typography></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>

                    <Grid item sm={4}>
                        <Box>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Datum</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Leads</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>Kunden</Typography></TableCell>
                                        <TableCell><Typography noWrap sx={{ fontSize: 15 }}>CVR</Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {tsxLeadPerYear().map(x =>
                                        <TableRow>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Text}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Amount}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{x.Convergens}</Typography></TableCell>
                                            <TableCell><Typography noWrap sx={{ fontSize: 10 }}>{(x.Convergens != 0 && x.Amount != 0) ? ((100 * x.Convergens / x.Amount).toFixed(2).toString() + " %").replace(".", ",") : "-"}</Typography></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>
                </Grid>


            </>
        )
    }

}
export default LeadsStatistic;