import React, { useState } from "react";
import { uploadFetch, useFetch } from "../hooks/useFetch";
import { IDinoGenerale } from "../interfaces/IDinoGenerale";
import { GenericOverview } from "../components/core/GenericOverview";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Check, Close } from "@mui/icons-material";
import { IClientType } from "../interfaces/IClientType";
import { ICompany } from "../interfaces/ICompany";
import { IContract } from "../interfaces/IContract";
import { IClient } from "../interfaces/IClient";
import { DiNoGeneraleEdit } from "../components/DiNoGenerale/DiNoGeneraleEdit";


export const DinoGenerale:React.FC = () => {
    const [dinoGeneraleArray, setDinoGeneraleArray, statusCodeDiNoGenerale] = useFetch<IDinoGenerale[]>("/dino_generale/");
    //
    const [clientArray, setClientArray, statusCodeClient] = useFetch<IClient[]>("/client/");
    const [serverTypeArray, setServerTypeArray, statusCodeServerType] = useFetch<IClientType[]>("/client/type");
    const [companyArray, setCompanyArray, statusCodeCompany] = useFetch<ICompany[]>("/company/");
    const [contractArray, setContractArray, statusCodeContract] = useFetch<IContract[]>("/company/contract/");
    //
    const [editDiNoGenerale,setEditDiNoGenerale] = useState<IDinoGenerale|null>(null)
    //
    const [isOpenSend,setIsOpenSend] = useState(false);
    const [isLoadingSave,setIsLoadingSave] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);



    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        }, 
        { 
            field: 'created_at', 
            headerName: 'Datum', 
            width: 180,
            renderCell: (params: GridRenderCellParams<any, string>) => new Date(String(params.value)).toLocaleString()
        }, 
        { 
            field: 'legal_transaction_type', 
            headerName: 'Vorgangsart', 
            width: 200 
        }, 
        { 
            field: 'is_processed', 
            headerName: 'Gesendet', 
            width: 90,
            renderCell: (params: GridRenderCellParams<any, boolean>) => (params.value) ? <Check/> : <Close/>
        }, 
        { 
            field: 'partner', 
            headerName: 'Partner', 
            width: 150 
        }, 
        { 
            field: 'partner_id', 
            headerName: 'Partner ID', 
            width: 300 
        }, 
        { 
            field: 'lead_first_name', 
            headerName: 'Vorname', 
            flex: 1
        }, 
        { 
            field: 'lead_last_name', 
            headerName: 'Nachname', 
            flex: 1
        }, 
        { 
            field: 'lead_email', 
            headerName: 'E-Mail', 
            flex: 1
        }, 
    ]


    const handleChangeId = (currentId:any) => {
        let testObject = dinoGeneraleArray?.find(x => x.id === Number(currentId));

        if (testObject !== undefined) {
            setEditDiNoGenerale(testObject);
        }
    }


    const handleAfterSave = (res:IDinoGenerale) => {
        if (dinoGeneraleArray !== undefined) {
            setDinoGeneraleArray([
                ...dinoGeneraleArray.map(x => x.id === res.id ? res : x)
            ])
            setIsOpenSend(false);
            setEditDiNoGenerale(null);
        }
    }


    const handleSave = (send_legaltransaction:boolean) => {
        if (editDiNoGenerale !== null) {
            let uploadedObject = {
                ...editDiNoGenerale,
                send_legaltransaction: send_legaltransaction
            }
            uploadFetch(`/dino_generale/${editDiNoGenerale.id}`,false,uploadedObject,handleAfterSave,setWasSuccessfullySaved,() => {},setIsLoadingSave)
        }
        

    }


    if (
        (statusCodeDiNoGenerale !== null && statusCodeDiNoGenerale !== 200)
    ) { return <Alert severity="error">Fehler!</Alert> }

    else if (
        dinoGeneraleArray === undefined
    ) { return <LinearProgress/> }
    else {
        return(
            <>
                <Dialog open={!wasSuccessfullySaved} onClose={() => setWasSuccessfullySaved(true)}>
                    <DialogContent>
                        <Alert severity="error" sx={{mb: 2}}>
                            Es ist ein Fehler aufgetretten!
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setWasSuccessfullySaved(true)}>Okay</Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={isOpenSend} onClose={() => setIsOpenSend(false)}>
                    <DialogContent>
                        {(editDiNoGenerale?.is_processed) &&
                            <Alert severity="warning" sx={{mb: 2}}>
                                Dieser Vorgang wurde bereits gesendet!
                            </Alert>
                        }
                        {(isLoadingSave) &&
                            <LinearProgress/>
                        }
                        Wollen Sie nach dem Speichern den Vorgang senden?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsOpenSend(false)}>Abbruch</Button>
                        <Button variant="contained" onClick={() => handleSave(false)}>Nein</Button>
                        <Button variant="contained" onClick={() => handleSave(true)}>Ja</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={editDiNoGenerale !== null} onClose={()=> setEditDiNoGenerale(null)} maxWidth="md" fullWidth>
                    <DialogTitle>DiNo Generale</DialogTitle>
                    <DialogContent>
                        {(editDiNoGenerale !== null ) &&
                            <DiNoGeneraleEdit
                                key={`edit-dino-generale-${editDiNoGenerale.id}`}
                                editDiNoGenerale={editDiNoGenerale}
                                setEditDiNoGenerale={setEditDiNoGenerale}
                                dinoGeneraleArray={dinoGeneraleArray}
                                setDinoGeneraleArray={setDinoGeneraleArray}
                                //
                                clientArray={clientArray || []}
                                companyArray={companyArray || []}
                                contractArray={contractArray || []}
                                serverTypeArray={serverTypeArray || []}
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setEditDiNoGenerale(null)}>Abbruch</Button>
                        <Button variant="contained" onClick={() => setIsOpenSend(true)}>Speichern</Button>
                    </DialogActions>
                </Dialog>


                <DataGrid
                    rows={dinoGeneraleArray}
                    columns={columns}
                    //getRowId={(row) => row.id}
                    onRowClick={(params,event,details) => handleChangeId(params.id)}
                    autoHeight
                />
            </>
        )
    }

}