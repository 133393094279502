import React, { useState } from "react";
import { IDinoGenerale } from "../../interfaces/IDinoGenerale";
import { IClient } from "../../interfaces/IClient";
import { IClientType } from "../../interfaces/IClientType";
import { ICompany } from "../../interfaces/ICompany";
import { IContract } from "../../interfaces/IContract";
import { Accordion, AccordionDetails, AccordionSummary, Collapse, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { JSONTree } from "react-json-tree";


interface IProps {
    editDiNoGenerale: IDinoGenerale;
    setEditDiNoGenerale: Function;
    dinoGeneraleArray: IDinoGenerale[];
    setDinoGeneraleArray: Function;
    //
    clientArray: IClient[];
    serverTypeArray: IClientType[];
    companyArray: ICompany[];
    contractArray: IContract[];
}


interface ISelectedClient {
    idCompany: number | null;
    idContract: number | null;
    idClient: number | null;
}

export const DiNoGeneraleEdit:React.FC<IProps> = ({
    editDiNoGenerale, setEditDiNoGenerale,
    dinoGeneraleArray, setDinoGeneraleArray,
    clientArray, serverTypeArray, companyArray, contractArray
}) => {
    const [selectedClient,setSelectedClient] = useState<ISelectedClient>(() => {
        let idCompany:number|null = null;
        let idContract:number|null = null;
        let idClient:number|null = null;

        if (editDiNoGenerale.Client_send !== null) {
            let testClient = clientArray.find(x => x.id === editDiNoGenerale.Client_send);
            if (testClient !== undefined) {
                let testContact = contractArray.find(x => testClient!.Contract === x.id);
                if (testContact !== undefined) {
                    idCompany = testContact.Company;
                    idContract = testContact.id;
                    idClient = testClient.id;
                }
            }
        } 

        return {
            idCompany: idCompany,
            idContract: idContract,
            idClient: idClient
        } as ISelectedClient
    });
    const [isDiNoCustomer,setIsDiNoCustomer] = useState(true);
    const [selectedNotary,setSelectedNotary] = useState(-1);

    return(
        <>
            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item xs={4}>
                    <TextField
                        label="Erstellt am"
                        value={new Date(String(editDiNoGenerale.created_at)).toLocaleString()}
                        fullWidth
                        disabled
                        size="small"
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        label="Vorgangsart"
                        value={editDiNoGenerale.legal_transaction_type}
                        fullWidth
                        disabled
                        size="small"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Partner"
                        value={editDiNoGenerale.partner === null ? "-" : editDiNoGenerale.partner}
                        fullWidth
                        disabled
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Partner"
                        value={editDiNoGenerale.partner_id === null ? "-" : editDiNoGenerale.partner_id}
                        fullWidth
                        disabled
                        size="small"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item xs={12}>
                    <CustomeTextField
                        label="Ziel: DiNo Kunde?"
                        object={{"isDiNoCustomer" : isDiNoCustomer}}
                        attr="isDiNoCustomer"
                        setObject={(res:{"isDiNoCustomer" : boolean}) => setIsDiNoCustomer(res.isDiNoCustomer)}
                        type="boolean"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Collapse in={isDiNoCustomer}>
                        <CustomeTextField
                            label="DiNo Kunde"
                            object={selectedClient}
                            attr="idCompany"
                            setObject={(res:ISelectedClient) => setSelectedClient({idCompany:res.idCompany, idContract:null, idClient: null})}
                            type="select"
                            null
                        >
                            {companyArray.map(x =>
                                <MenuItem key={`select-company-${x.id}`} value={x.id}>
                                    {x.customer_number_lextorbyte}: {x.company_name}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                    </Collapse>
                </Grid>
                <Grid item xs={6}>
                    <Collapse in={isDiNoCustomer && selectedClient.idCompany !== null}>
                        <CustomeTextField
                            label="Vertrag"
                            object={selectedClient}
                            attr="idContract"
                            setObject={(res:ISelectedClient) => {
                                setSelectedClient({...res, idClient: null});
                            }}
                            type="select"
                            null
                        >
                            {contractArray.map(x =>
                                <MenuItem key={`select-contract-${x.id}`} value={x.id}>
                                    {x.contract_title}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                    </Collapse>
                </Grid>
                <Grid item xs={6}>
                    <Collapse in={isDiNoCustomer && selectedClient.idContract !== null}>
                        <CustomeTextField
                            label="DiNo-Instanz"
                            object={selectedClient}
                            attr="idClient"
                            setObject={(res:ISelectedClient) => {
                                setSelectedClient(res);
                                setEditDiNoGenerale({...editDiNoGenerale, Client_send: res.idClient, extern_notary: null})
                            }}
                            type="select"
                            null
                        >
                            {clientArray.filter(x => x.ClientType !== 1).filter(x => x.Contract === selectedClient.idContract).map(x =>
                                <MenuItem key={`select-client-${x.id}`} value={x.id}>
                                    {(x.ClientType === 2) ? "DiNo Öffentlich" : "DiNo Generale"}, {x.ip}, {x.servername_fqdn}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                    </Collapse>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{mt: -2}}>
                <Collapse in={!isDiNoCustomer}>
                    <CustomeTextField
                        label="Externe Kunde"
                        object={editDiNoGenerale}
                        attr="extern_notary"
                        
                        setObject={(res:IDinoGenerale) => {
                            setEditDiNoGenerale({...res, Client_send: null});
                            setSelectedClient({ idClient:null, idCompany: null, idContract: null} as ISelectedClient)
                        }}
                        type="string"
                        null
                    />
                </Collapse>
            </Grid>

            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item xs={12}>
                    <CustomeTextField
                        label="Bemerkung"
                        object={editDiNoGenerale}
                        attr="note"
                        setObject={setEditDiNoGenerale}
                        type="string"
                        null
                        rows={5}
                    />
                </Grid>
            </Grid>

            <Accordion sx={{mt: 2}}>
                <AccordionSummary>Sync File</AccordionSummary>
                <AccordionDetails>
                    <JSONTree
                        data={JSON.parse(editDiNoGenerale.sync_file)}
                    />
                </AccordionDetails>
            </Accordion>

        </>
    )
}